import * as React from "react";
import Layout from "../components/Layout";
import Home from "./Home";

const App = () => {
  return (
    <Layout fullWidth={true}>
      <Home />
    </Layout>
  )
};

export default App;
